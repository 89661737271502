import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Markdown from 'markdown-to-jsx'

import Layout from '../components/layout'
import SEO from '../components/seo'
import PageHero from '../components/hero/page'
import InfoCard from '../components/infocard'
import Contact from '../components/contact'
import HorizontalForm from '../components/forms/horizontal'

import styles from '../styles/page.module.scss'

const IndexPage = () => (
  <StaticQuery
    query={graphql`
      query AboutUsContentsQuery {
        currentPage: allContentYaml(filter: { url: { eq:"/how-it-works" } }) {
          edges {
            node {
              title
              content
              description
              heroLeadLine
              heroLine
              heroPoints
              infoCards {
                cardTitle
                cardImage
                cardDescription
              }
            }
          }
        }

        globalSettings: allContentYaml(filter: {
          type: { eq: "GlobalSettings" }
        }) {
          edges {
            node {
              callToActionTitle
            }
          }
        }

        allFile(filter: { extension: { eq: "svg" } }) {
          edges {
            node {
              name
              url: publicURL
            }
          }
        }
      }
    `}
    render={data => {
      const page = data.currentPage.edges[0].node
      const settings = data.globalSettings.edges[0].node

      return (
        <Layout>
          <SEO
            title={ page.title }
            description={ page.description }
            canonical="https://motorinjuryhelpline.com.au/how-it-works"
          />

          <PageHero
            leadLine={ page.heroLeadLine }
            heroLine={ page.heroLine }
            heroPointsLead={
              `Depending on your circumstances, you may be able to claim 
              compensation for your:`
            }
            heroPoints={ page.heroPoints }
          />

          <div className={ styles.infoCards }>
            <div
              className={ styles.content }
              style={{
                background: 'none'
              }}
            >
              <div className={ `${ styles.content__override } ${ styles.content__container }` }>
                <Markdown
                  children={ page.content }
                />
              </div>
            </div>
            <div className={ styles.infoCards__flex }>
              { page.infoCards.map((info, index) => {
                let image

                data.allFile.edges.forEach(edge => {
                  if (edge.node.name === info.cardImage) {
                    image = edge.node.url
                  }
                })

                return <InfoCard
                  image={ image }
                  title={ info.cardTitle }
                  description={ info.cardDescription }
                  key={ index }
                  homepage
                />
              })}
            </div>

            <HorizontalForm
              formTitle={ settings.callToActionTitle }
            />
          </div>

          <Contact />

        </Layout>
      )
    }
    }
  />
)

export default IndexPage
